import React, { useEffect } from "react";
import "./navBar.css";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <header>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>

        {/* <li>
                    <NavLink to="/Bookings">
                    Booking Calendar
                    </NavLink>
                    </li>

                <li> */}
        {/* <NavLink to="/Memberships">
                    Open Studio Memberships
                    </NavLink>
                    </li> */}

        {/* <li>
          <NavLink to="/Shop">Shop</NavLink>
        </li> */}

        {/* <li>
          <NavLink to="/Workshops">Workshops</NavLink>
        </li> */}

        <li>
          <NavLink to="/AboutUs">About Us</NavLink>
        </li>

        {/* <li>
          <NavLink to="/ContactUs">Contact Us</NavLink>
        </li> */}

      </ul>
    </header>
  );
}

export default NavBar;
