import React from "react";
import './Footer.css';
import { AiFillInstagram } from 'react-icons/ai';


function Footer () {
    return (
        <section className="footerComponent">

            <div className="footerSections"> 

    <div className="footerHolder">
      
    <a href="https://www.instagram.com/animalhousepottery/">
               
      <AiFillInstagram className="instagramHolder"/>
           </a>  

           <br></br>
           
              <span>
                © Animal House Pottery 2023 Disclaimer | Privacy Policy
              </span>

    </div>

            </div>
                
                    {/* <h1>Contact</h1>
                    <p>Tel: 702-505-9269  Las Vegas</p>
                    <p>619-671-0400  San Diego</p>
                    <p>909-605-6336 Ontario</p>
​ */}
                    {/* <p> <a href='mailto:Sales@GLogisticsFulfillment.com'>
                    Sales@GLogisticsFulfillment.com 
                    </a>
                    </p> */}


                
        </section>
    )
}

export default Footer;