// TODO: anthony needs to provide the biography. and discuss with him about photo if thats what he wants, etc. 

import React from "react";
import aboutUsDemo from "../assets/images/aboutUsDemo.jpg";
import ceramicsDisplay from "../assets/images/ceramicsDisplay2.jpg";
import '../assets/css/AboutUs.css';
import potterySet from "../assets/images/potterySet.jpg";

function AboutUs () {
    return (
      <div className="aboutus-Page">
        <div className="headingContainer">
          <div className="headingImageContainer"
            style={{backgroundImage: `url(${aboutUsDemo})` }}>

            <div className="headingwordsContainer">
              <h1>About Us</h1>
              <h2> How Anthony Started </h2>
            </div>

       
          </div>



          <div className="bioTextContainer">
            <p>
              I started pottery by chance; I needed an elective in school, and
              my counselor asked: home economics or ceramics? I said, What’s
              ceramics? I went into the class, my teacher/ father figure Frank
              Polito said, "Let me show you what you are going to do for the rest
              of the year."
              </p>

              <p>
                That was it; I was obsessed. I asked Frank what I could do to do
                more clay. He said you can become my teacher aid; I did. I mixed
                clay, loaded and unloaded kilns, mixed glazes, and mopped the
                floors. I volunteered for him for 4 years after high school. I
                earned a Bachelor of Arts at UNLV. I had a great foundation, and
                by chance again, I moved to Maui in 2013–2016. I worked as a
                production potter making Raku.
              </p>

              <p>
              It's always been fire, smoke, and mystery. While learning and
              making, I explore ways to make pieces, questioning texture, form,
              and my own internal design. l've alway taught my process, I help
              other potters ask themselves what they want to make. I help with
              their process and ideas. When I came back to Las Vegas I started
              with a idea to open a studio. Animal House Pottery was coined by
              Frank Polito, who felt it was playful and encouraged to attack the
              clay like a animal. The clay will become what ever you imagine to
              make it, mind over clay.
            </p>

          </div>
        </div>




        <div className="aboutUsWrapper" style={{backgroundImage: `url(${ceramicsDisplay})` }}>
        {/* <img src={ceramicsDisplay} /> */}

          <div className="aboutusContainer">
            <div className="aboutusparagraphs">
            
              <p className="potteryDifferent">
                What makes Animal House Pottery stand out among other studios?
                <br></br>
                <br></br>
                Animal House Pottery stands out because of its flexible open
                studio (24/7 access), allowing individuals the time needed to
                learn about this beautiful material. I do my best to share all
                the information I have about clay. Someone experiencing clay for
                the first time can see, feel, and develop ideas about how clay
                works, all while having some laughs.
              </p>
            

              <p className="studioEnvironment">
                In Animal House Pottery, how is personalized mentoring
                demonstrated in the studio environment?
                <br />
                <br></br>

                At Animal House Pottery, personalized mentoring is demonstrated
                by focusing on each individual. I ask them what they like,
                referring to pictures, drawings, or ideas they might already
                have. I facilitate their journey by encouraging them to have fun
                exploring clay. For example, Sheldon watched The Great Pottery
                Throw Down and wanted to try throwing on the potter's wheel.
                After a few attempts, he didn't feel connected with clay, so we
                did a handbuilding session. He excelled, then returned to the
                potter's wheel and improved significantly. He eventually
                combined both techniques. Sheldon's experience inspired him to
                pursue a Master's in Ceramics in Oahu.
              </p>

              <br />

            </div>
          </div>
        </div>
        <div className="potterySet">
        <img src={potterySet} /> 

              <p>
                What advice would you give to newbie potters regarding
                expectations and reality in learning the process of this type of
                art?
                <br />
                <br />
                In other words, how do you not make it stressful when it's
                challenging to create your vision of what you want? I think it's
                comforting to know that clay is unbiased and has a mind of its
                own. You can do pottery for 50 years and still encounter
                challenges, such as cracks, while someone just starting may have
                a flawless piece. Everyone develops a thicker skin and learns to
                accept that it's just clay. I emphasize best practices that
                increase the odds of success, although nothing in life is
                guaranteed, and clay is no different. I often say that everyone
                who perseveres eventually achieves their goals. In my 20-plus
                years, I've never met anyone who hasn't succeeded with
                persistence.
              </p>
          </div>
          
      </div>
    );
}


           
            




export default AboutUs;