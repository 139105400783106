import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Footer from "./components/Footer";
import ContactUs from "./pages/ContactUs";
import Memberships from "./pages/Memberships";
import Bookings from "./pages/Bookings";
import Workshops from "./pages/Workshops";
import Shop from "./pages/Shop";


function  Content(){
    return (
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path="/"element={<Home></Home>}></Route>
            </Routes>

             <Routes>
                <Route path="/AboutUs"element={<AboutUs></AboutUs>}></Route>
            </Routes>

            {/* <Routes>
                <Route path="/Memberships"element={<Memberships></Memberships>}></Route>
            </Routes> */}

            {/* <Routes>
                <Route path="/ContactUs"element={<ContactUs></ContactUs>}></Route>
            </Routes> */}

            {/* <Routes>
                <Route path="/Bookings"element={<Bookings></Bookings>}></Route>
            </Routes> */}


            {/* <Routes>
                <Route path="/Shop"element={<Shop></Shop>}></Route>
            </Routes> */}
            
            {/* <Routes>
                <Route path="/Workshops"element={<Workshops></Workshops>}></Route>
            </Routes>  */}
            

            <Footer></Footer> 
        </BrowserRouter>

    )
}

export default Content;

