import React from "react";

import "../assets/css/Home.css";
import formingPottery from "../assets/images/formingPottery.jpg";
import onwheeldecor from "../assets/images/onwheeldecor.jpg";
import purpleBadgePot from "../assets/images/purpleBadgePot.jpg";
import bluePurplePot from "../assets/images/bluePurplePot.jpg";
import potteryReel from "../assets/videos/replayPotteryReel.mp4";
import memberWheel from "../assets/images/MemberWheel.jpg";
import glazeDemo from "../assets/images/GlazingDemoEdited.jpg";
import homePottery from "../assets/images/homePottery.jpg";
import raikuSculpture from "../assets/images/raikuSculpture.jpg";

function Home() {
  return (
    <section className="home-Page">
      <div className="headlineContainer">
        <img src={formingPottery} />
        <div className="headlinetextTitle">
          <p> Animal House Pottery </p>
          {/* TODO: we have to add the forming pottery into the CSS- we have to figure out how to do that to help with the Curving.  */}
        </div>

        <div className="headlineTagline">
          <p> Where Personalized Mentoring is Valued and Encouraged! </p>
        </div>
      </div>

      <div className="collageHolder">
        <div className="placeholderSmall">
          <img src={raikuSculpture} />{" "}
        </div>

        <div className="placeholderMedium">
          <video controls autoplay loop>
            <source src={potteryReel} type="video/mp4" />
          </video>
        </div>
        
        <div className="placeholderSmall">
          <img src={homePottery} />
        </div>


        <div className="placeholderSmall">
          <img src={memberWheel} />
        </div>

        <div className="placeholderSmall">
          <img src={glazeDemo} />
        </div>
      </div>
    </section>
  );
}

export default Home;
